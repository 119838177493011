<template>
  <div class="detial-item" v-if="data">
    <div class="base-info">
      <fm-title title-text="申请信息"></fm-title>
      <base-info size="auto" :data="data"></base-info>
    </div>
    <div class="deal-recode show-detail-record">
      <fm-title title-text="附件"></fm-title>
      <parm-file :data="data" :fun="{get: true}"></parm-file>
    </div>
    <div class="step-list">
      <fm-title title-text="审批记录"></fm-title>
      <step-info :statusMap="statusMap" :currentStatus="data.status" :config="statusConfig[data.type]" :recodeList="data.statusActionRecord || []"></step-info>
    </div>
    <div class="deal-recode show-detail-record" >
      <record :statusMap="statusMap" :recordList="data.statusActionRecord || []"></record>
    </div>
  </div>
</template>

<script>
import baseInfo from './baseInfo'
import record from './record'
import stepInfo from './stepInfo'
import parmFile from './parmFile'

import {
  assetsRepairApplyRequest
} from '@/api'

export default {
  components: {
    baseInfo,
    record,
    stepInfo,
    parmFile
  },
  data () {
    return {
      show: false,
      data: {},
      typeList: [{key: 'mini', label: '价值2000元以下'}, {key: 'norm', label: '价值2000元以上'}]
    }
  },
  watch: {
    id: {
      deep: true,
      handler () {
        this.loadData()
      },
      immediate: true
    }
  },
  props: {
    id: { type: Number },
    statusConfig: { type: Object, defualt: () => {} },
    statusMap: { type: Object, defualt: () => {} }
  },
  methods: {
    async loadData () {
      if (!this.id) {
        return
      }
      let datas = await assetsRepairApplyRequest.get({
        statusActionRecord: 1,
        id: this.id
      })
      datas.forEach(v => {
        v.fileIds = (v.fileIds || '').split(',').filter(v => v).map(v => Number(v))
        v.typeLabel = (this.typeList.find(v1 => v1.key === v.type) || {label: ''}).label
      })
      this.data = datas[0]
    }
  }
}
</script>

<style lang="less" scoped>
.detial-item {
  position: relative;
  border-radius: 3px;
  .right-btns {
    position: absolute;
    top: 0;
    right: 0px;
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .recode-switch {
    display: flex;
    justify-content: center;
  }
  .icon-jiantouyou {
    font-size: 20px;
    color: #F4628F;
    line-height: 1.2;
  }
  .back-i {
    transform:rotate(-90deg) !important;
  }
  .deal-recode {
    padding-top: 10px;
    border-top: 1px solid #e8eaec;
    overflow-y:auto;
    max-height: 200px;
  }
  .step-list {
    overflow-x: auto;
  }
}
</style>